import React from 'react'

import {
  Button,
  Chip,
  CopyAction,
  PendingContent,
} from '@lattice/common/components'
import { ReactComponent as UnlinkIcon } from '@lattice/assets/icons/carbon/unlink.svg'

export type IConnectedResourceProps = {
  title?: React.ReactNode
  icon?: React.ReactNode
  loading?: boolean
  copyable?: boolean
  connectResourceText?: React.ReactNode
  connected?: {
    resourceName: string
    transformResourceName?: (username: string) => string
  }
  additionalContent?: {
    static?: React.ReactNode
    connected?: React.ReactNode
    disconnected?: React.ReactNode
  }
  onConnectClick?: () => void
  onDisconnectClick?: () => void
}

export const ConnectedResource = ({
  title,
  icon,
  loading,
  copyable,
  connectResourceText,
  connected,
  additionalContent,
  onConnectClick,
  onDisconnectClick,
}: IConnectedResourceProps) => {
  return (
    <div className="flex flex-col w-full gap-2.5">
      {title && <h4 className="text-[#F3F4F6] font-medium text-sm">{title}</h4>}
      <PendingContent
        className="w-full"
        variants={['min-height']}
        loading={loading}
      >
        {!connected && (
          <div>
            <Button
              variants={['primary-outlined', 'full-width']}
              className={'hover:!bg-[#f0c519]/10 gap-2'}
              onClick={onConnectClick}
            >
              {icon}
              {connectResourceText}
            </Button>
          </div>
        )}
        {connected && (
          <>
            <div className="flex flex-row justify-between items-center py-2.5 px-4 border border-white/15 rounded-md bg-[#232733]">
              <div className="flex flex-row items-center gap-2.5 text-gray-400 text-base">
                {icon}{' '}
                <span>
                  {connected.transformResourceName
                    ? connected.transformResourceName(connected.resourceName)
                    : connected.resourceName}
                </span>
                {copyable && <CopyAction value={connected.resourceName} />}
              </div>
              <Chip
                variants={['mini']}
                className="gap-1.5 md:!flex !hidden"
                onClick={onDisconnectClick}
              >
                Unlink <UnlinkIcon />
              </Chip>
            </div>
            <Button
              variants={['primary-outlined', 'full-width']}
              className={'hover:!bg-[#f0c519]/10 gap-2 !flex md:!hidden'}
              onClick={onDisconnectClick}
            >
              Unlink <UnlinkIcon />
            </Button>
          </>
        )}
        {additionalContent && additionalContent.static}
        {additionalContent && !connected && additionalContent.disconnected}
        {additionalContent && connected && additionalContent.connected}
      </PendingContent>
    </div>
  )
}
