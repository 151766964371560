import React from 'react'
import { WagmiProvider as WagmiLibProvider } from 'wagmi'

import { WagmiConfig } from './config'

const WagmiProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <WagmiLibProvider config={WagmiConfig} reconnectOnMount={false}>
      {children}
    </WagmiLibProvider>
  )
}

export { WagmiProvider }
