import React from 'react'
import clsx from 'clsx'

import { ReactComponent as Copy } from '@lattice/assets/images/svgs/copy.svg'

export type ICopyAction = {
  value?: any
  className?: string
}

export const CopyAction = ({ value, className }: ICopyAction) => {
  const doCopy = async () => {
    try {
      await navigator.clipboard.writeText(String(value))
    } catch (e) {
      console.log('Copy action failed:')
      console.error(e)
    }
  }

  return (
    <Copy
      onClick={doCopy}
      className={clsx(
        'inline-flex cursor-pointer',
        !className?.includes('size-') && 'size-4',
        className
      )}
    />
  )
}
