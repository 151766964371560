import React, { useEffect } from 'react'
import cls from 'classnames'

import {
  BaseCard,
  CopyAction,
  PendingContent,
} from '@lattice/common/components'
import { ReactComponent as ConstellationIcon } from '@lattice/assets/icons/custom/constellation.svg'
import { ReactComponent as SwitchOffIcon } from '@lattice/assets/icons/custom/switch-off.svg'
import { ReactComponent as SwitchOnIcon } from '@lattice/assets/icons/custom/switch-on.svg'
import { ReactComponent as BaseNetworkIcon } from '@lattice/assets/icons/custom/base-network.svg'
import { ReactComponent as UserAvatarIcon } from '@lattice/assets/icons/carbon/user-avatar.svg'
import { ReactComponent as TwitterXIcon } from '@lattice/assets/icons/custom/twitter_x.svg'
import { ReactComponent as YouTubeIcon } from '@lattice/assets/icons/custom/youtube.svg'
import { ReactComponent as TrophyIcon } from '@lattice/assets/icons/carbon/trophy.svg'
import { useUserWalletsQueries } from '@lattice/common/queries/bundles'
import {
  formatNumber,
  NumberFormat,
  shortenAddress,
  useAsyncAction,
} from '@lattice/utils'
import {
  useConfirmActionProvider,
  useWalletProvider,
  useUserOAuthProvider,
  useUserProvider,
} from '@lattice/common/providers'
import {
  FetchStatus,
  isFetchStatus,
  OAuthProviderURI,
} from '@lattice/common/consts'
import { useUserQueries } from '@lattice/common/queries/bundles/user'
import { CurrencyNetwork } from '@lattice/common/lib'

import styles from './view.module.scss'
import { ConnectedResource } from './components/ConnectedResource'
import { SocialRankRequirement } from './components/SocialRankRequirement'

export const SocialsWalletsView = () => {
  const { user, userDbData } = useUserProvider()

  const {
    operations,
    userOAuthProviderStatuses,
    doRequestOAuthFlowForProvider,
    requestUserOAuthStatusForProvider,
    unregisterUserOAuthToken,
  } = useUserOAuthProvider()
  const { requestConfirmation } = useConfirmActionProvider()
  const { activeWallet, requestConnectorActivation } = useWalletProvider()
  const wallets = useUserWalletsQueries()
  const userQueries = useUserQueries()

  const doUnlinkAccount = async (provider: OAuthProviderURI) => {
    const confirmation = await requestConfirmation({
      title: 'Unlink account',
      description:
        provider === OAuthProviderURI.TWITTER_X
          ? 'Are you sure that you want to unlink your X account? You will need to link a new account to start collecting rewards again.'
          : 'Are you sure that you want to unlink your YouTube account? You will need to link a new account to start collecting rewards again.',
      negativeButtonContent: 'Cancel',
      positiveButtonContent: 'Yes, unlink',
    })

    if (!confirmation) {
      return
    }

    await unregisterUserOAuthToken(provider)
    requestUserOAuthStatusForProvider(provider)
  }

  const [doRegisterWallet, { loading: loadingRegister }] = useAsyncAction(
    async (network: CurrencyNetwork, primary: boolean) => {
      if (activeWallet.status !== 'connected') {
        await requestConnectorActivation()
        return
      }

      if (
        network === CurrencyNetwork.CONSTELLATION &&
        activeWallet.constellation
      ) {
        const ownershipToken =
          await activeWallet.constellation.requestWalletOwnershipToken()

        await wallets.walletRegisterMut.mutateAsync({
          address: activeWallet.constellation.account,
          network: CurrencyNetwork.CONSTELLATION,
          primary,
        })

        await wallets.walletVerifyMut.mutateAsync({
          address: activeWallet.constellation.account,
          network: CurrencyNetwork.CONSTELLATION,
          ownershipToken,
        })

        await userQueries.socialRankStats.refetch()
        return
      }

      if (network === CurrencyNetwork.ETHEREUM && activeWallet.ethereum) {
        const ownershipToken =
          await activeWallet.ethereum.requestWalletOwnershipToken()

        await wallets.walletRegisterMut.mutateAsync({
          address: activeWallet.ethereum.account,
          network: CurrencyNetwork.ETHEREUM,
          primary,
        })

        await wallets.walletVerifyMut.mutateAsync({
          address: activeWallet.ethereum.account,
          network: CurrencyNetwork.ETHEREUM,
          ownershipToken,
        })

        await userQueries.socialRankStats.refetch()
        return
      }
    },
    []
  )

  const [doUnlinkWallet, { loading: loadingUnlink }] = useAsyncAction(
    async (address: string, network: CurrencyNetwork) => {
      const confirmation = await requestConfirmation({
        title: 'Unlink wallet',
        description:
          'Are you sure that you want to unlink your  wallet? You will need to link a new wallet to start collecting rewards again.',
        negativeButtonContent: 'Cancel',
        positiveButtonContent: 'Yes, unlink',
      })

      if (!confirmation) {
        return
      }

      await wallets.walletUnlinkMut.mutateAsync({
        address,
        network,
      })

      await userQueries.socialRankStats.refetch()
    },
    []
  )

  const providerStatus_twitter =
    userOAuthProviderStatuses.resource[OAuthProviderURI.TWITTER_X]

  const providerStatus_google =
    userOAuthProviderStatuses.resource[OAuthProviderURI.GOOGLE]

  const primaryConstellationWallet =
    wallets.wallets.data !== FetchStatus.NOT_FOUND
      ? wallets.wallets.data?.find(
          (wallet) =>
            wallet.network === CurrencyNetwork.CONSTELLATION && wallet.primary
        ) ?? null
      : null

  const primaryEthereumWallet =
    wallets.wallets.data !== FetchStatus.NOT_FOUND
      ? wallets.wallets.data?.find(
          (wallet) =>
            wallet.network === CurrencyNetwork.ETHEREUM && wallet.primary
        ) ?? null
      : null

  useEffect(() => {
    requestUserOAuthStatusForProvider(OAuthProviderURI.TWITTER_X)
    requestUserOAuthStatusForProvider(OAuthProviderURI.GOOGLE)
  }, [user])

  return (
    <section className={styles.main}>
      <BaseCard className={{ root: styles.card, body: styles.body }}>
        <div className={styles.title}>
          <h2>Social Accounts</h2>
          <span>Link your social accounts to start getting rewards.</span>
        </div>
        <ConnectedResource
          title="Twitter / X"
          icon={<TwitterXIcon />}
          loading={[
            operations.doRequestOAuthFlowForProvider.status ===
              FetchStatus.PENDING,
            operations.checkAndFinishOAuthFlow.status === FetchStatus.PENDING,
            operations.unregisterUserOAuthToken.status === FetchStatus.PENDING,
            operations.doRequestOAuthFlowForProvider.status !==
              FetchStatus.PENDING &&
              userOAuthProviderStatuses.status === FetchStatus.PENDING,
          ].some((v) => !!v)}
          connectResourceText="Connect account"
          connected={
            providerStatus_twitter?.status === 'registered'
              ? {
                  resourceName:
                    '@' + providerStatus_twitter.publicRecord.username,
                }
              : undefined
          }
          onConnectClick={() =>
            doRequestOAuthFlowForProvider(OAuthProviderURI.TWITTER_X)
          }
          onDisconnectClick={() => doUnlinkAccount(OAuthProviderURI.TWITTER_X)}
          additionalContent={{
            connected: (
              <PendingContent
                variants={['min-height']}
                loading={userQueries.twitterxStats.isLoading}
              >
                <div className="flex flex-col gap-3">
                  <h4 className="text-[#F3F4F6] font-medium">
                    Social Reach Stats
                  </h4>
                  <div className="flex flex-col p-4 gap-4 bg-[#2D313D] border-0.5 border-white/25 rounded-md">
                    <div className="flex flex-col gap-2">
                      <h5 className="text-[#F3F4F6] font-medium">
                        Twitter / X followers
                      </h5>
                      <p className="text-white/65 font-normal">
                        This is the number of followers you have on Twitter/X .
                        The count updates daily, so there might be a slight
                        delay in reflecting recent changes.
                      </p>
                    </div>
                    <div className="flex flex-row items-center gap-2 text-lg text-[#EED03A] font-medium">
                      {!isFetchStatus(userQueries.twitterxStats.data) &&
                      typeof userQueries.twitterxStats.data?.followersCount ===
                        'number' ? (
                        <>
                          <UserAvatarIcon className="size-6" />
                          {formatNumber(
                            userQueries.twitterxStats.data.followersCount,
                            NumberFormat.WHOLE
                          )}
                        </>
                      ) : (
                        'Processing your information, it will be available soon...'
                      )}
                    </div>
                  </div>
                </div>
              </PendingContent>
            ),
          }}
        />
        <ConnectedResource
          title="YouTube"
          icon={<YouTubeIcon className="size-6" />}
          loading={[
            operations.doRequestOAuthFlowForProvider.status ===
              FetchStatus.PENDING,
            operations.checkAndFinishOAuthFlow.status === FetchStatus.PENDING,
            operations.unregisterUserOAuthToken.status === FetchStatus.PENDING,
            operations.doRequestOAuthFlowForProvider.status !==
              FetchStatus.PENDING &&
              userOAuthProviderStatuses.status === FetchStatus.PENDING,
          ].some((v) => !!v)}
          connectResourceText="Connect account"
          connected={
            providerStatus_google?.status === 'registered'
              ? { resourceName: providerStatus_google.publicRecord.username }
              : undefined
          }
          onConnectClick={() =>
            doRequestOAuthFlowForProvider(OAuthProviderURI.GOOGLE)
          }
          onDisconnectClick={() => doUnlinkAccount(OAuthProviderURI.GOOGLE)}
        />
      </BaseCard>
      <BaseCard className={{ root: styles.card, body: styles.body }}>
        <div className={styles.title}>
          <h2>Wallet Addresses</h2>
          <span>
            Link your wallet address from different networks to start getting
            rewards.
          </span>
        </div>
        <div className={styles.items}>
          <PendingContent
            variants={['min-height']}
            loading={wallets.wallets.isLoading || loadingUnlink}
          >
            <ConnectedResource
              title="Constellation address"
              icon={<ConstellationIcon className="size-6" />}
              loading={loadingRegister}
              copyable
              connectResourceText="Link wallet"
              connected={
                primaryConstellationWallet !== null
                  ? {
                      resourceName: primaryConstellationWallet.address,
                      transformResourceName: (v) => shortenAddress(v, 6, 6),
                    }
                  : undefined
              }
              onConnectClick={() =>
                doRegisterWallet(CurrencyNetwork.CONSTELLATION, true)
              }
              onDisconnectClick={() =>
                primaryConstellationWallet &&
                doUnlinkWallet(
                  primaryConstellationWallet.address,
                  primaryConstellationWallet.network
                )
              }
              additionalContent={{
                static: (
                  <span className="text-sm text-gray-400 italic ">
                    Don't have a Stargazer wallet yet? Download it{' '}
                    <a
                      className="text-[#EED03A] underline"
                      target="_blank"
                      rel="noreferrer"
                      href="https://chromewebstore.google.com/detail/stargazer-wallet/pgiaagfkgcbnmiiolekcfmljdagdhlcm"
                    >
                      here
                    </a>
                  </span>
                ),
              }}
            />
            <ConnectedResource
              title="BASE / Ethereum address"
              icon={<BaseNetworkIcon className="size-6" />}
              loading={loadingRegister}
              copyable
              connectResourceText="Link wallet"
              connected={
                primaryEthereumWallet !== null
                  ? {
                      resourceName: primaryEthereumWallet.address,
                      transformResourceName: (v) => shortenAddress(v, 6, 6),
                    }
                  : undefined
              }
              onConnectClick={() =>
                doRegisterWallet(CurrencyNetwork.ETHEREUM, true)
              }
              onDisconnectClick={() =>
                primaryEthereumWallet &&
                doUnlinkWallet(
                  primaryEthereumWallet.address,
                  primaryEthereumWallet.network
                )
              }
              additionalContent={{
                static: (
                  <span className="text-sm text-gray-400 italic ">
                    Don't have a Coinbase wallet yet? Download it{' '}
                    <a
                      className="text-[#EED03A] underline"
                      target="_blank"
                      rel="noreferrer"
                      href="https://chromewebstore.google.com/detail/coinbase-wallet-extension/hnfanknocfeofbddgcijnmhnfnkdnaad"
                    >
                      here
                    </a>
                  </span>
                ),
              }}
            />
          </PendingContent>
        </div>
      </BaseCard>
      <BaseCard className={{ root: styles.card, body: styles.body }}>
        <div className={styles.title}>
          <h4>Allow wallet addresses and social accounts to be Public</h4>
          <span className={styles.description}>
            This information will be shared with the ElPaca Metagraph to be able
            to send rewards to your primary DAG address based on your twitter
            activity and EVM/ETH wallet activity.
            <br />
            <br />
            By enabling this setting, you are agreeing to have your socials and
            wallet address shared with 3rd party projects, in order to be used
            according to their own needs.
          </span>
        </div>
        <div className={styles.items}>
          <div className={styles.optin}>
            {userDbData.status === FetchStatus.PENDING ||
            userQueries.optinSocialWalletsMut.isPending ? (
              <PendingContent variants={['min-height']} />
            ) : (
              <>
                {userDbData.resource?.optinSocialWalletsInformationAt ===
                null ? (
                  <SwitchOffIcon
                    onClick={() =>
                      userQueries.optinSocialWalletsMut.mutate({
                        consent: true,
                      })
                    }
                  />
                ) : (
                  <SwitchOnIcon
                    onClick={() =>
                      userQueries.optinSocialWalletsMut.mutate({
                        consent: false,
                      })
                    }
                  />
                )}{' '}
                <span>Opt-in (Public)</span>
              </>
            )}
          </div>
        </div>
      </BaseCard>
      <BaseCard className={{ root: styles.card, body: styles.body }}>
        <div className={styles.title}>
          <h4>Rewards eligibility</h4>
          <span className={styles.description}>
            To be eligible for $DAG rewards from posting, you’ll need to meet
            these 5 requirements:
          </span>
        </div>
        <div className={styles.items}>
          <div className={styles.link}>
            {userQueries.socialRankStats.isLoading ||
            isFetchStatus(userQueries.socialRankStats.data) ? (
              <PendingContent variants={['min-height']} />
            ) : (
              <>
                <div className="flex flex-col p-4 border border-gray-700 rounded-md gap-4 bg-[#2D313D]">
                  <SocialRankRequirement
                    name="A valid linked X account"
                    fulfilled={
                      userQueries.socialRankStats.data?.requirementTwitterLinked
                    }
                  />
                  <SocialRankRequirement
                    name="A valid DAG address linked"
                    fulfilled={
                      userQueries.socialRankStats.data
                        ?.requirementConstellationWalletLinked
                    }
                  />
                  <SocialRankRequirement
                    name="A valid BASE / Ethereum address linked"
                    fulfilled={
                      userQueries.socialRankStats.data
                        ?.requirementEthereumWalletLinked
                    }
                  />
                  <SocialRankRequirement
                    name="Opt-in to sharing information"
                    fulfilled={
                      userQueries.socialRankStats.data?.requirementOptIn
                    }
                  />
                  <SocialRankRequirement
                    name="In the Top 150 Social Rank of eligible users"
                    fulfilled={
                      userQueries.socialRankStats.data?.requirementSocialRank
                    }
                  />
                </div>
                <div className={cls(styles.reachStat, 'bg-[#2D313D]')}>
                  <div className={styles.info}>
                    <div className={styles.title}>
                      <TrophyIcon />
                      <span>Social Rank</span>
                    </div>
                    <div className={styles.description}>
                      Your rank shows how your Twitter/X follower count stacks
                      up against others on Lattice, and unlock access to
                      exclusive ElPaca community activities.
                    </div>
                  </div>
                  <div className={styles.value}>
                    {!isFetchStatus(userQueries.socialRankStats.data) &&
                    typeof userQueries.socialRankStats.data?.rankPosition ===
                      'number'
                      ? '#' +
                        formatNumber(
                          userQueries.socialRankStats.data.rankPosition,
                          NumberFormat.WHOLE
                        )
                      : '--'}
                  </div>
                </div>

                {(() => {
                  const requirementCounts =
                    [
                      userQueries.socialRankStats.data?.requirementOptIn,
                      userQueries.socialRankStats.data?.requirementSocialRank,
                      userQueries.socialRankStats.data
                        ?.requirementTwitterLinked,
                      userQueries.socialRankStats.data
                        ?.requirementConstellationWalletLinked,
                      userQueries.socialRankStats.data
                        ?.requirementEthereumWalletLinked,
                    ].reduce((pv, cv) => pv + (cv ? 1 : 0), 0) + 4

                  return (
                    <div className="flex flex-col gap-4 p-4 border-0.5 border-[#F0C519] rounded-md">
                      {requirementCounts === 5 && (
                        <>
                          <div className="flex flex-col gap-2">
                            <h3 className="text-[#EED03A] font-medium">
                              Congrats, you're in the Top 150 and eligible for
                              $PACA rewards 😎
                            </h3>
                            <p className="text-white/65 text-sm">
                              You can now start collecting daily rewards when
                              posting with any of the following tags.{' '}
                            </p>
                          </div>
                          <div className="flex flex-col gap-3">
                            <h4 className="text-[#B6B9C8] font-medium text-sm">
                              Include one of the tags below in your post:
                            </h4>
                            <div className="flex flex-col py-2.5 px-3.5 gap-0.5 border-0.5 border-white/25 rounded-md">
                              {['$DAG', '#AmericasBlockchain'].map((tag) => (
                                <span
                                  key={tag}
                                  className="flex flex-row items-center gap-1.5 text-[#F3F4F6] text-sm"
                                >
                                  {tag}{' '}
                                  <CopyAction value={tag} className="size-3" />
                                </span>
                              ))}
                            </div>
                          </div>
                        </>
                      )}
                      {requirementCounts !== 5 && (
                        <>
                          <div className="flex flex-col gap-2">
                            <h3 className="text-white font-medium">
                              You're almost there 😀
                            </h3>
                            <p className="text-white/65 text-sm">
                              Just a little more to go—complete the last few
                              requirements to become eligible for daily rewards!{' '}
                            </p>
                          </div>
                          <div className="flex flex-col gap-3">
                            <h4 className="text-[#B6B9C8] font-medium text-sm">
                              Include one of the tags below in your post:
                            </h4>
                            <div className="flex flex-col py-2.5 px-3.5 gap-0.5 border-0.5 border-white/25 rounded-md">
                              {['$DAG', '#AmericasBlockchain'].map((tag) => (
                                <span
                                  key={tag}
                                  className="flex flex-row items-center gap-1.5 text-[#F3F4F6] text-sm"
                                >
                                  {tag}{' '}
                                  <CopyAction value={tag} className="size-3" />
                                </span>
                              ))}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )
                })()}
              </>
            )}
          </div>
        </div>
      </BaseCard>
    </section>
  )
}
