import React from 'react'
import cls from 'classnames'
import { ImpulseSpinner } from 'react-spinners-kit'

import styles from './component.module.scss'

export type IPendingContentProps = {
  variants?: ('min-height' | 'available-height')[]
  loading?: boolean
  children?: React.ReactNode
  className?: string
}

export const PendingContent = ({
  variants,
  loading,
  children,
  className,
}: IPendingContentProps) => {
  if (children && !loading) {
    return children
  }

  return (
    <div
      className={cls(
        styles.main,
        variants?.includes('min-height') && styles.minHeight,
        variants?.includes('available-height') && styles.heightAvailable,
        className
      )}
    >
      <ImpulseSpinner frontColor="#fc0" />
    </div>
  )
}
