import { sepolia, mainnet, polygon, polygonMumbai } from 'wagmi/chains'
import { createConfig, http } from 'wagmi'

import { AvailableConnectors } from '@lattice/common/consts'
import { EvmChain, EvmChainData } from '@lattice/common/lib'

declare module 'wagmi' {
  interface Register {
    config: typeof WagmiConfig
  }
}

const WagmiConfig = createConfig({
  chains: [mainnet, sepolia, polygon, polygonMumbai],
  transports: {
    [mainnet.id]: http(EvmChainData[EvmChain.ETHEREUM_MAINNET].rpcEndpoint),
    [sepolia.id]: http(EvmChainData[EvmChain.ETHEREUM_TESTNET].rpcEndpoint),
    [polygon.id]: http(EvmChainData[EvmChain.POLYGON_MAINNET].rpcEndpoint),
    [polygonMumbai.id]: http(
      EvmChainData[EvmChain.POLYGON_TESTNET].rpcEndpoint
    ),
  },
  connectors: Object.values(AvailableConnectors).map(
    (connectorInfo) => connectorInfo.connector
  ),
  multiInjectedProviderDiscovery: false,
})

export { WagmiConfig }
