import React from 'react'

import { ReactComponent as CheckmarkOutlineIcon } from '@lattice/assets/icons/carbon/checkmark-outline.svg'
import { ReactComponent as CloseOutlineIcon } from '@lattice/assets/icons/carbon/close-outline.svg'

export type ISocialRankRequirementProps = {
  name: React.ReactNode
  fulfilled?: boolean
}

export const SocialRankRequirement = ({
  name,
  fulfilled,
}: ISocialRankRequirementProps) => (
  <div className="flex flex-col gap-2 md:flex-row md:justify-between">
    <span className="text-[#F3F4F6]">{name}</span>
    {fulfilled ? (
      <span className="text-[#34D399] flex gap-2 items-center">
        <CheckmarkOutlineIcon className="lg:hidden h-5 w-5" />
        Yes
        <CheckmarkOutlineIcon className="hidden lg:inline h-5 w-5" />
      </span>
    ) : (
      <span className="text-[#B6B9C8] flex gap-2 items-center">
        <CloseOutlineIcon className="lg:hidden h-5 w-5" />
        No
        <CloseOutlineIcon className="hidden lg:inline h-5 w-5" />
      </span>
    )}
  </div>
)
